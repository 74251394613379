import classes from './Carousel.module.scss'

export type CarouselTheme = {
  carousel: string
  control: string
  controlNext: string
  controlPrev: string
  controlDisabled: string
  controlHideDisabled: string
}

export class ThemedCarousel {
  theme: CarouselTheme
  readonly carousel: string

  constructor(theme?: Partial<CarouselTheme>) {
    this.theme = { ...defaultTheme, ...theme }
    this.carousel = cn(classes.carousel, this.theme.carousel)
  }

  control({
    modifier,
    disabled = false,
    hideDisabled = false,
    hideOnMobile = false,
  }: {
    modifier: 'prev' | 'next'
    disabled?: boolean
    hideDisabled?: boolean
    hideOnMobile?: boolean
  }) {
    return cn(
      classes.control,
      this.theme.control,
      modifier === 'next' ? classes.m_next : classes.m_prev,
      modifier === 'next' ? this.theme.controlNext : this.theme.controlPrev,
      {
        [classes.is_disabled]: disabled,
        [this.theme.controlDisabled]: disabled,
        [classes.hide_disabled]: hideDisabled,
        [classes.hide_on_mobile]: hideOnMobile,
        [this.theme.controlHideDisabled]: hideDisabled,
      }
    )
  }
}

const defaultTheme: CarouselTheme = {
  carousel: '',
  control: '',
  controlNext: '',
  controlPrev: '',
  controlDisabled: '',
  controlHideDisabled: '',
}

export const Carousel = new ThemedCarousel()
