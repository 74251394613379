import { ButtonType } from '@app/components/Button/ButtonType'

import classes from './ButtonType.module.scss'

export class ButtonTypePrimary implements ButtonType {
  constructor(
    private block?: boolean,
    private small?: boolean
  ) {}

  getClassName() {
    return cn(classes.button, classes.primary, { [classes.block]: this.block, [classes.small]: this.small })
  }

  static instance = new ButtonTypePrimary()
  static instanceBlock = new ButtonTypePrimary(true)
  static instanceSmall = new ButtonTypePrimary(false, true)
}
