import React, { forwardRef, PropsWithChildren } from 'react'

import classes from './Spinner.module.scss'

export type SpinnerProps = {
  className?: string
  animating?: boolean
}

export type SpinnerRef = HTMLDivElement

export const Spinner = forwardRef<SpinnerRef, PropsWithChildren<SpinnerProps>>(function Spinner({ className, animating, children }, ref) {
  return (
    <div className={cn(classes.spinner_string, className)} ref={ref}>
      <div className={classes.content}>
        <span className={cn(classes.spinner, { [classes.m_animating]: animating })} />
        {!!children && <span>{children}</span>}
      </div>
    </div>
  )
})
