import React, { ReactNode, useMemo, useRef, useState } from 'react'

import { CarouselControls } from '@app/components/Carousel/CarouselControls'
import { AutoHeightContainer, SliderGallery, SliderGalleryRef, useGalleryOffsetsGetter } from '@app/components/SliderGallery/SliderGallery'

import classes from './Gallery.module.scss'

export const useGallery: (obj: { items: ReactNode[]; padderClassname?: string; contentClassname?: string }) => {
  controls: React.ReactNode
  gallery: React.ReactNode
} = ({ items, padderClassname, contentClassname }) => {
  const galleryRef = useRef<SliderGalleryRef>(null)
  const cntRef = useRef<HTMLDivElement>(null)
  const startPadderRef = useRef<HTMLDivElement>(null)
  const endPadderRef = useRef<HTMLDivElement>(null)

  const [bounds, setBounds] = useState({ hasPrev: false, hasNext: false })

  const getOffsets = useGalleryOffsetsGetter({ cntRef, startPadderRef, endPadderRef })

  const pager = useMemo(
    () => ({
      hasPrev: bounds.hasPrev,
      hasNext: bounds.hasNext,
      handlePrevClick: () => galleryRef.current?.prev(),
      handleNextClick: () => galleryRef.current?.next(),
    }),
    [bounds]
  )

  return {
    controls: (
      <div className={classes.controls}>
        <CarouselControls controlClassName={classes.icon} hideOnMobile={false} pager={pager} />
      </div>
    ),
    gallery: (
      <SliderGallery getOffsets={getOffsets} onBoundsChange={setBounds} ref={galleryRef}>
        <div className={classes.cnt}>
          <div className={padderClassname} ref={startPadderRef} />
          <AutoHeightContainer className={cn(classes.content_cnt, contentClassname)} ref={cntRef}>
            {items}
          </AutoHeightContainer>
          <div className={padderClassname} ref={endPadderRef} />
        </div>
      </SliderGallery>
    ),
  }
}
