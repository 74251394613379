import React, { FunctionComponent } from 'react'

import { useAppDispatch } from '@app/utils/redux'

import { Icon, loadIcon } from '@app/components/Icon/Icon'

import { Carousel } from './Carousel'

export const CarouselControls: FunctionComponent<{ pager: Pager; hideOnMobile?: boolean; controlClassName?: string; iconClassName?: string }> = ({
  pager,
  hideOnMobile = true,
  controlClassName,
  iconClassName,
}) => {
  const dispatch = useAppDispatch()
  const { hasPrev, hasNext, handlePrevClick, handleNextClick } = pager

  if (!IS_BROWSER) {
    dispatch(loadIcon('18', 'chevron-left'))
    dispatch(loadIcon('18', 'chevron-right'))
  }

  if (!hasPrev && !hasNext) return null

  return (
    <React.Fragment>
      <button className={cn(Carousel.control({ modifier: 'prev', disabled: !hasPrev, hideOnMobile }), controlClassName)} onClick={handlePrevClick}>
        <Icon className={iconClassName} icon="chevron-left" />
      </button>
      <button className={cn(Carousel.control({ modifier: 'next', disabled: !hasNext, hideOnMobile }), controlClassName)} onClick={handleNextClick}>
        <Icon className={iconClassName} icon="chevron-right" />
      </button>
    </React.Fragment>
  )
}

type Pager = { hasPrev: boolean; hasNext: boolean; handlePrevClick: () => unknown; handleNextClick: () => unknown }
