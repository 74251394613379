import React, { ReactNode } from 'react'

import { useEvent } from '@app/hooks/useEvent'

import { InView } from '@app/routes/Static/components/InView/InView'

import { Spinner } from '@app/components/Spinner/Spinner'

import { useGallery } from './Gallery'

import classes from './LandingGallery.module.scss'

export const useLandingGallery: (data: {
  items: { id: string; node: ReactNode }[]
  itemClassname?: string
  contentClassname?: string
  loadMore?: () => unknown
}) => {
  controls: React.ReactNode
  gallery: React.ReactNode
} = ({ items, itemClassname, contentClassname, loadMore }) => {
  const handleLoadMore = useEvent((inView: boolean) => {
    if (!inView) return
    return loadMore?.()
  })

  const gallery = useGallery({
    items: (() => {
      const elements = items.map(item => {
        return (
          <div className={cn(classes.gallery_item, itemClassname)} key={item.id}>
            {item.node}
          </div>
        )
      })

      if (loadMore)
        elements.push(
          <div className={cn(classes.gallery_item, classes.gallery_item_loading, itemClassname)} key="loading">
            <InView onChange={handleLoadMore}>
              <Spinner animating={true} />
            </InView>
          </div>
        )

      return elements
    })(),
    contentClassname,
    padderClassname: classes.gallery_padder,
  })

  return {
    controls: gallery.controls,
    gallery: <div className={classes.gallery_root}>{gallery.gallery}</div>,
  }
}
